/**
 * LM-LIFF-01-2 綁定流程-更新手機門號 https://lmliff.cht.com.tw/signed/legacy-number/
 */
import { useEffect } from 'react';

import { useGetUserInfo } from '@/apis/hooks/useGetUserInfo';
import LoadingView from '@/components/SharedLayout/LoadingView';
import useLoading from '@/hooks/useLoading';
import ModuleValidation from '@/modules/ModuleValidation';

const LegacyNumberValidatePage = () => {
  const { isLoading, showLoading, hideLoading } = useLoading();
  const {
    userInfo,
    isSuccess,
    isLoading: isUserInfoLoading,
  } = useGetUserInfo();

  useEffect(() => {
    if (isUserInfoLoading || !isSuccess) showLoading();
    else hideLoading();
  }, [hideLoading, isSuccess, isUserInfoLoading, showLoading]);

  useEffect(() => {
    if (!isLoading) console.log(userInfo);
  }, [isLoading, showLoading, userInfo]);
  return isLoading ? (
    <LoadingView />
  ) : (
    <ModuleValidation linkFormData={{ mobile: userInfo.mobile || '' }} />
  );
};

export default LegacyNumberValidatePage;
